import React from 'react';
import Section from '../layout/Section.js';
import SEO from '../components/SEO.js';
import styled from 'styled-components';
import ContactForm from '../components/ContactForm.js';
import Hero from '../components/Hero.js';
import IntroSection from '../components/IntroSection.js';
import GridColumn from '../layout/Grid/GridColumn';
import GridContainer from '../layout/Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import phoneIcon from '../assets/phone.svg';
import mailIcon from '../assets/envelope.svg';
// import IconAirport from "../components/Icons.js";

const StyledIconLink = styled.a`
  font-family: var(--sans-serif);
  font-size: var(--font-l);
  display: inline-block;
  position: relative;
  left: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 400;
  border-bottom: none;
  /* border-bottom: 1px solid; */
  /* :first-of-type {
		margin-top: 4rem;
	} */
  &:first-of-type:before {
    content: url(${phoneIcon});
    position: absolute;
    height: 1em;
    width: 1em;
    left: -2.5rem;
    top: 40%;
    transform: translateY(-50%);
  }
  &:last-of-type:before {
    content: url(${mailIcon});
    position: absolute;
    height: 1em;
    width: 1em;
    left: -2.5rem;
    top: 40%;
    transform: translateY(-50%);
  }
`;

export default function KontaktPage({ data }) {
  return (
    <>
      <SEO title={data.page.seoTitle} description={data.page.seoDescription} />

      <Hero hero={data.page} />
      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <IntroSection intro={data.page} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section color="subtle">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="7">
              <h2>Kontakt</h2>
              <StyledIconLink
                className="mt-4"
                href={`tel:${data.global.companyAddress.phone}`}
              >
                {data.global.companyAddress.phone}
              </StyledIconLink>
              <br />
              <StyledIconLink
                href={`mailto:${data.global.companyAddress.email}`}
              >
                {data.global.companyAddress.email}
              </StyledIconLink>
            </GridColumn>
          </GridRow>

          <GridRow>
            <GridColumn start="3" span="7">
              <ContactForm className="mt-4" />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

export const query = graphql`
  query KontaktQuery {
    page: sanityMainPages(slug: { current: { eq: "kontakt" } }) {
      headline
      seoTitle
      seoDescription
      _rawIntroCopy
      heroHeadline
      enableHero
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    global: sanityCompanyInfo(_id: { eq: "companyInfo" }) {
      companyAddress {
        email
        phone
      }
    }
  }
`;
