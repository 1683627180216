import React from 'react';
import { useState } from 'react';
import { navigate } from 'gatsby-link';
import styled from 'styled-components';

const StyledForm = styled.form`
  width: 100%;
  font-family: var(--sans-serif);
  font-size: var(--font-button);

  fieldset {
    width: 100%;
    border: none;
    padding-left: 0;
  }

  .select-wrapper {
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    width: 100%;
    line-height: 1.2;
    background-color: #fff;
    /* background-image: linear-gradient(to top, #f9f9f9, #fff 33%); */
    &:after {
      content: '1';
      width: 0.8em;
      height: 0.5em;
      background-color: var(--text);
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      grid-area: select;
      justify-self: end;
      z-index: 1;
      position: absolute;
      right: 1em;
    }
  }

  select {
    // reset
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    z-index: 1;
    grid-area: select;
  }

  input,
  select,
  textarea {
    outline: none;
    width: 100%;
    min-height: 2.5rem;
    padding: 1em;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: white;
  }

  input,
  textarea {
    &:focus {
      border-color: var(--brand);
    }
  }

  button {
    cursor: pointer;
    border: none;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    min-width: 17ch;
    line-height: 1.2;
    font-size: var(--font-button);
    padding: 1em 2em;
    color: white;
    background-color: var(--brand);
    border-radius: 6em;
    font-family: var(--sans-serif);
    transition: all 0.2s ease;
    &:hover,
    &:active {
      background-color: var(--brand-70);
    }
  }
`;

export default function ContactForm({ className }) {
  // https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#step-2
  // https://github.com/sw-yx/gatsby-netlify-form-example-v2/blob/master/src/pages/contact.js
  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  const [state, setState] = useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // const handleSelectChange = (e) => {
  //   setState({ ...state, [e.target.name]: e.options[e.selectedIndex].value })
  // }

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <>
      <StyledForm
        action="/success"
        method="post"
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className={className}
      >
        <input type="hidden" name="contact-form" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

        <fieldset>
          <label htmlFor="name">Vor- und Nachname*</label>
          <br />
          <input type="text" name="name" required onChange={handleChange} />
        </fieldset>
        <fieldset>
          <label htmlFor="email">E-Mail-Adresse*</label>
          <br />
          <input type="email" name="email" required onChange={handleChange} />
        </fieldset>

        <fieldset>
          <label htmlFor="message">Nachricht*</label>
          <br />
          <textarea
            name="message"
            rows="8"
            required
            onChange={handleChange}
          ></textarea>
        </fieldset>
        <button type="submit" className="button mt-2">
          Nachricht senden
        </button>
      </StyledForm>
    </>
  );
}
